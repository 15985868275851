@import "../styles/theme.less";

html {
	height: 100%;
	width: 100%;
}

body {
	font-family: "Mulish", open-sans;
	width: 100%;
	height: 100%;
}

.form-input {
	/*padding: 12.5px 11px;*/
	padding: 9.5px 11px;
	background: white !important;
	border-radius: 5px;
}

.spinner-white {
	color: #ffffff;
}

::placeholder {
	font-size: 12px;
}

.bread-crumb-container {
	margin-top: -55px;
}

.ant-picker {
	width: 100%;
}

button.auth-buttons {
	width: 100% !important;
}

button.auth-buttons,
.header-btn,
.custom-modal-btn {
	background: #colors[darkgray];
	outline: none;
	color: white;
	border: none;
	border-radius: 3px;
	height: 45px;
}

.header-btn {
	width: 15% !important;
	cursor: pointer;
}

.custom-modal-btn {
	width: 70% !important;
}

.logo {
	height: 32px;
	margin: 16px;
	background: rgba(255, 255, 255, 0.2);
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 16px;
}

.site-layout-sub-header-background {
	background: #fff;
}

.site-layout-background {
	background: #fff;
}

.ant-layout-header .site-layout-sub-header-background {
	padding-left: 20px;
	font-size: 20px;
}

.site-layout-sub-header-background {
	background: #fff;
	padding-left: 20px !important;
	font-size: 20px !important;
}

.ant-alert-message > ul {
	margin: 0;
	padding-left: 0;
	list-style: none !important;
}

.advanced-search {
	display: flex;
	justify-content: center;
}

.advanced-search-inputs {
	width: 60%;
	background: #fafafa;
	padding: 30px 10px 10px 10px;
	border-radius: 7px;
	border: 1px solid #f1ecec;
	margin-bottom: 23px;
	&.reports {
		width: 100% !important;
	}
}

@primary-color: #1DA57A;